// SeriesButtons.js
import React, { useEffect } from 'react';
import apiClient from '../services/httpService';
import { useDispatch } from 'react-redux';
import { setQuestions, setSelectedTest } from '../store/questionsSlice';


const SeriesButtons = ({ tests=[] }) => {
  const dispatch = useDispatch();

  const fetchQuestions = async (id) => {
    dispatch(setSelectedTest(id));
    try {
      const response = await apiClient.get(`/mgmt/questions/${id}/`);
      if (response.status === 200) {
        dispatch(setQuestions(response.data));
      } else {
        console.error('Error fetching questions:', response.data);
      }
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  };

  return (
    <div className="grid grid-cols-5 gap-2 ">
      {tests.map((value, i) => (
        <button onClick={() => fetchQuestions(value.id)} key={i} className="py-3 border-[2px] border-tcfbackground rounded-md bg-tcfbackground text-sm  hover:bg-white  font-bold focus:bg-white">
          {value.name}
        </button>
      ))}
    </div>
  )
};

export default SeriesButtons;
