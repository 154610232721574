import React, { useCallback, useEffect } from 'react';
import TabView from './TabView';
import TaskNumbers from './TaskNumbers';
import ResponseSection from './ComprehensionEcriteTestSection';
import apiClient from '../services/httpService';
import { useDispatch, useSelector } from 'react-redux';
import ProgressBar from './ProgressBar';
import { setCurrentIndex } from '../store/questionsSlice';

const ComprenensionEcriteSection = () => {
    const [tests, setTests] = React.useState([]);
    const { questions, currentIndex } = useSelector((state) => state.questions);

    const getTestsForCategory = async () => {
        try {
            const response = await apiClient.get('/mgmt/tests/001/');
            if (response.status === 200) {
                setTests(response.data);
            }
        } catch (error) {
            console.error('Failed to fetch tests', error);
        }
    };

    useEffect(() => {
        getTestsForCategory();
    }, []);
    

    return (
        <div className="flex flex-col gap-4">
            <TabView tests={tests} />
            <TaskNumbers questions={questions?.normal_questions} />
            <ResponseSection question={questions?.normal_questions[currentIndex]} />
            <ProgressBar totalTime={questions?.duration} />
        </div>
    );
};

export default ComprenensionEcriteSection;
