import React from 'react';
import homelogo from '../images/TCFlogo2.png';

const Footer = () => {
  
  return (

    <div className="pt-[64px] px-[67px] xl:px-[102px] pb-[49px] bg-[#00211C] text-white ">
      
      <div className='flex pt-[41px] justify-between '>
      <a href="/">
        <img src={homelogo} alt="Home Logo" className="h-[90px] w-[198px] " />
      </a>
        <div className='grid grid-cols-3  justify-items-end' >
          <div className='font-light '>
            <p className=' mb-[17px] text-lg text-[#2CFF00]'> Nos Contacts </p>
            <p>Phone: 1-514-922-5004</p>
            <p>Whatsapp: 1-514-922-5004</p>
            <p>SMS: 1-514-922-5004</p>
            <p>Email: test@mail.com</p>
          </div>
          <div className='font-light  '>
            <p className=' mb-[17px] text-lg text-[#2CFF00]'>Les Pages </p>
            <p>Compréhension Écrite</p>
            <p>Compréhension Orale</p>
            <p>Expression Écrite</p>
            <p>Expression Orale</p>
          </div>
          <div className='font-light   '>
            <p className=' mb-[17px] text-lg text-[#2CFF00]'> Les Méthodologies </p>
            <p>Compréhension Écrite</p>
            <p>Compréhension Orale</p>
            <p>Expression Écrite</p>
            <p >Expression Orale</p>
            <button className="bg-[#2CFF00]  text-black font-semibold text-lg px-[40px] py-[11px] rounded-md mt-7  ">Call Us</button>

          </div>
        </div>
       
      </div>


      <div className='border-t border-[3px] border-[#9BCF53] mt-[79px]'></div>
      <h1 className=' mt-[26px]  font-normal items-center'>© 2024 TCF Canada. All rights reserved.</h1>

    </div>

  )
}

export default Footer

