// SeriesButtons.js
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentIndex } from '../store/questionsSlice';

const TestButtons = ({questions}) => {
  const { currentIndex } = useSelector((state) => state.questions);
  const dispatch = useDispatch();
  return(
    <div className="grid grid-cols-3 gap-7 ">
      {questions?.map((question, i) => {
        let bgColor = "bg-gray-300"; // Default background color

        if (question.revise_question && !question.response) {
          bgColor = "bg-yellow-500"; // Revise question with no response
        }
        if (question.response) {
          bgColor = "bg-green-500"; // Question has a response
        }
        if (i === currentIndex) {
          bgColor = "bg-blue-500"; // Current question
        }
        return (<button onClick={() => dispatch(setCurrentIndex(i))} key={i} className={`py-4 border ${bgColor} border-white rounded-lg bg-white hover:bg-tcfbackground text-lg focus:bg-tcfbackground`}>
          Tache {question?.task_number}
        </button>)
      })}
  </div>
)};

export default TestButtons;
