import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentIndex } from '../store/questionsSlice';

const TaskNumbers = ({ questions }) => {
  const { currentIndex } = useSelector((state) => state.questions);
  const dispatch = useDispatch();

  return (
    <div className="grid grid-cols-12 gap-y-2 bg-white p-4 rounded-[15px] justify-items-center">
      {questions?.map((question, i) => {
        let bgColor = "bg-gray-300"; // Default background color

        if (question.revise_question && !question.response) {
          bgColor = "bg-yellow-500"; // Revise question with no response
        }
        if (question.response) {
          bgColor = "bg-green-500"; // Question has a response
        }
        if (i === currentIndex) {
          bgColor = "bg-blue-500"; // Current question
        }

        return (
          <button
            key={i}
            onClick={() => dispatch(setCurrentIndex(i))}
            className={`w-10 h-10 ${bgColor} text-sm font-bold text-gray-800 hover:bg-tcfbackground rounded-full flex items-center justify-center leading-none ${i === currentIndex && question.revise_question ? "text-white" : ""
              }`}
          >
            {i + 1}
          </button>
        );
      })}
    </div>
  );
};

export default TaskNumbers;
