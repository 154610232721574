import React, { useEffect } from 'react';
import TaskButtons from './TaskButtons';
import TestSection from './ExpressionEcriteTestSection';
import TabView from './TabView';
import apiClient from '../services/httpService';
import { useSelector } from 'react-redux';
import ProgressBar from './ProgressBar';

const ExpressionEcriteSection = () => {
    const [tests, setTests] = React.useState([]);
    const { questions, currentIndex } = useSelector((state) => state.questions);


    const getTestsForCategory = async () => {
        try {
            const response = await apiClient.get('/mgmt/tests/003/');
            if (response.status === 200) {
                setTests(response.data);
            }
        } catch (error) {
            console.error('Failed to fetch tests', error);
        }
    };
    useEffect(() => {
        getTestsForCategory();
    }, []);
    return (

        <div className="flex flex-col gap-4">
            <TabView tests={tests} />
            <TaskButtons questions={questions?.expression_questions} />
            <TestSection question={questions?.expression_questions[currentIndex]} />
            <ProgressBar totalTime={questions?.duration} />
        </div>

    );
};

export default ExpressionEcriteSection

