import React from 'react';
import bannerimage from "../images/bannerimage.jpg"
import tcf from "../images/TCF.png"
import homepagestudents from "../images/homepagestudents.png"
import learn from "../images/learn.png"
import settings from "../images/settings.png"
import timer from "../images/timer.png"
import announcement from "../images/announcement.png"
import checkmarkIcon from '../images/checkmark.svg'
import greencheckmarkIcon from '../images/checkmark2.svg'
import logo from '../images/TCFlogo.png'
import assist from '../images/helpcenter.png'
import InputField from '../components/InputField';
import background from '../images/backgroundhorizontal.png'
import profileimage from '../images/profileimage.jpg'
import HomeNavigation from '../components/Navbar';
import Footer from '../components/Footer'
import { useNavigate } from 'react-router-dom';
import logoImage from '../images/loginimagec.png';




const Home = () => {

  const navigate = useNavigate()

  const cardData = [
    {
      plan: "ATHRY",
      duration: "30 Days",
      price: "USD 1",
      features: ["Nous offrons un suivi particulier", "Nous offrons un suivi particulier", "Nous offrons un suivi particulier",],
    },
    {
      plan: "PLATINUM",
      duration: "30 Days",
      price: "USD 28",
      features: ["Nous offrons un suivi particulier", "Nous offrons un suivi particulier", "Nous offrons un suivi particulier",],
    },
    {
      plan: "ATHRY",
      duration: "30 Days",
      price: "USD 1",
      features: ["Nous offrons un suivi particulier", "Nous offrons un suivi particulier", "Nous offrons un suivi particulier",],
    },
  ];

  function Card({ plan, duration, price, features, isGreen }) {
    return (
      <div className={`px-[30px] py-[47px]   shadow-lg rounded-[20px] flex flex-col items-center ${isGreen ? "bg-[#057e43] text-white" : "bg-white text-black border border-[#80808000"}`}>
        <h3 className="text-3xl font-normal">{plan}</h3>
        <p className={`text-5xl font-bold mt-2 ${isGreen ? " text-[#2CFF00]" : ''}`}>{price}</p>
        <p className={`text-lg  ${isGreen ? " text-white" : 'text-[#5C5C5C]'}`}>{duration}</p>
        <div className={`border-t mt-4 w-full ${isGreen ? "border-white " : 'border-[#787676]'}`}></div>
        <ul className="mt-4 space-y-6">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center gap-[6px]  text-sm">
              <div className={`flex items-center justify-center border w-[15px] h-[15px]  rounded-full  ${isGreen ? " border-[#2CFF00]" : 'border-black'}`}>
                <img src={isGreen ? greencheckmarkIcon : checkmarkIcon} alt="checkmark icon" className={`w-[10px] h-[10px]  ${isGreen ? " fill-[#2CFF00]" : 'fill-black'} `} />
              </div> {feature}
            </li>
          ))}
        </ul>
        <button className={`text-white font-semibold text-lg px-[74px] py-[11px] rounded-md mt-[80px] ${isGreen ? " bg-[#2CFF00] " : 'bg-gradient-to-r from-[#038B14] to-[#2BFF00]'}`} >Nos Formations</button>
      </div>
    );
  }




  const clientData = [
    {
      image: profileimage,
      username: "Abraham Lao ",
      handle: "@diallomaria",
      testimonial: "J’ai fini mon teste TCF en 15 jours et j‘avoue c’etait dure mais satisfaisant"
    },
    {
      image: profileimage,
      username: "Mariam Diallo",
      handle: "@diallomaria",
      testimonial: "J’ai fini mon teste TCF en 15 jours et j‘avoue c’etait dure mais satisfaisant"
    },
    {
      image: profileimage,
      username: "Erica Zambo",
      handle: "@diallomaria",
      testimonial: "J’ai fini mon teste TCF en 15 jours et j‘avoue c’etait dure mais satisfaisant",
    },
  ];




  function ClientCard({ image, username, handle, testimonial }) {
    return (
      <div className='px-[32px] pt-[24px] pb-[42px] gap-[41px]  text-[#12372A] bg-[#EDF4F8] shadow-lg rounded-[20px] flex flex-col text-start '>
        <div className='flex gap-[9px] items-center '>
          <img src={image} alt="User" className="w-[60px] h-[60px] rounded-full object-cover" />
          <div>
            <p className="text-start text-lg font-bold mt-2">{username}</p>
            <p className="text-start text-base text-gray-500">{handle}</p>
          </div>
        </div>
        <p className="text-lg w-[286px] text-gray-500">{testimonial}</p>
      </div>
    );
  }

  return (
    <div className='flex flex-col overflow-x-hidden'>
      {/* Navbar at the top */}
      <HomeNavigation />



      {/* Content Sections */}
      <div className=" h-min-screen pt-[85px]"> {/* Padding top to avoid overlap with fixed navbar */}
        {/* Section 1 */}
        <section className=" w-full h-[80%] relative overflow-hidden   ">


          <img src={bannerimage} alt="Header" className="w-full h-full object-cover transform scale-x-[-1]" />
          <div className="absolute inset-0 " style={{
            backgroundImage: `
linear-gradient(to right,  rgba(0, 0, 0, .8), rgba(0, 0, 0, 0) 100%)

`,
          }} ></div> {/* Dark overlay */}


          <div className=" absolute inset-0 right-0 flex   px-[98px]">
            {/* <div className="absolute inset-0 " style={{
              backgroundImage: `
linear-gradient(to right,  rgba(0, 0, 0, 1), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 53%),
linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0) 18%)
`,
            }}></div> */}

            <div className='flex flex-col gap-[142px] z-10'>
              <div className=' flex flex-col items-start pt-[128px] gap-[7px] relative  '>
                {/* Text content - placed above the overlay */}
                <h2 className="w-[350px] text-7xl font-medium  text-white">Se preparer au tcf Canada</h2>
                <p className="w-[385px] text-lg  text-white font-light">Plateforme dediée à la préparation au tcf canada suivant les conditions réelles de l’examen</p>
                <div className='flex gap-3  mt-[200px]'>
                  <button className="bg-[#2CFF00]  font-semibold text-lg px-[40px] py-[11px] rounded-md  ">Get started</button>
                  <button onClick={() => { navigate("/signup") }} className=' flex  text-white  px-[53px] items-center justify-center text-base lg:text-sm border border-[#2CFF00]  py-[11px] rounded-md flex-shrink-0 leading-[18.9px]  font-medium'>Sign Now</button>
                </div>
              </div>

            </div>
          </div>
        </section>

        {/* Section 2 */}
        <section className=" flex px-[29px]  xl:px-[67px] pt-14  justify-between  ">
          <div className='w-[40%]'>
            <h2 className="text-4xl font-bold text-start  ">Démarrez votre aventure canadienne avec assurance</h2>
            <img src={logoImage} alt="se" className=" mt-4" />

          </div>
          <div className=' grid grid-cols-2 gap-10 pb-8'>

            <div className="relative flex items-center h-[240px]  w-[300px] justify-center bg-[#d3f2f1] px-[6px]  rounded-[10px]">
              {/* Image Positioned Above */}
              <div className="absolute -top-[50px] right-50 left-50 ">
                <img
                  src={learn}
                  alt="learn"
                  className="h-[190px] relative z-10"
                />
              </div>
              <div className='text-center space-y-2'>
                {/* Content Below Image */}
                <h2 className="mt-[90px] text-2xl font-medium ">Apprentissage avancé</h2>
                <p className="text-lg font-medium ">
                  Préparez-vous avec des tests précis et des simulations réelles
                  d’examen.
                </p>
              </div>
            </div>

            <div className="relative flex items-center h-[240px]  w-[300px] justify-center  bg-[#d3f2f1] px-[6px]  rounded-[10px]">
              {/* Image Positioned Above */}
              <div className="absolute -top-[50px] right-50 left-50 ">
                <img
                  src={settings}
                  alt="settings"
                  className="h-[190px] relative z-10"
                />
                {/* <img src={settings} alt="se" className="w-[177px] h-[189px] mb-[16px]" /> */}
              </div>
              <div className='text-center space-y-2'>
                {/* Content Below Image */}
                <h2 className="mt-[90px] text-2xl font-medium ">Cours
                  Personnalisés</h2>
                <p className="text-lg font-medium ">
                  Des programmes adaptés à votre
                  rythme pour une maîtrise complète
                  du TCF.
                </p>
              </div>
            </div>

            <div className="relative flex items-center h-[240px] w-[300px] justify-center bg-[#d3f2f1] px-[6px]  rounded-[10px]">
              {/* Image Positioned Above */}
              <div className="absolute -top-[50px] right-50 left-50 ">
                <img
                  src={timer}
                  alt="timer"
                  className="h-[190px] relative z-10"
                />
              </div>
              <div className='text-center space-y-2'>
                {/* Content Below Image */}
                <h2 className="mt-[90px] text-2xl font-medium ">Expertise Prouvée</h2>
                <p className="text-lg font-medium ">
                  Profitez de l’expérience de formateurs
                  qui ont guidé plus de 600 réussites
                  annuelles.
                </p>
              </div>
            </div>

            <div className="relative flex items-center h-[240px]  w-[300px] justify-center bg-[#d3f2f1] px-[6px]  rounded-[10px]">
              {/* Image Positioned Above */}
              <div className="absolute -top-[50px] right-50 left-50 ">
                <img
                  src={announcement}
                  alt="announcement"
                  className="h-[190px] relative z-10"
                />
              </div>
              <div className='text-center space-y-2'>
                {/* Content Below Image */}
                <h2 className="mt-[90px] text-2xl font-medium ">Investissement Intelligent</h2>
                <p className="text-lg font-medium ">
                  L'excellence à des prix justes, investissez dans la qualité, récoltez la réussite.
                </p>
              </div>
            </div>

          </div>


        </section>
        <section className='bg-gradient-to-r from-[#038B14] to-[#2BFF00] py-2'>
          <div className='text-white flex flex-row gap-[55px]  justify-around'>
            <div className='flex flex-col items-center'>
              <h2 className=" text-7xl font-semibold">210</h2>
              <h2 className="">Incrits</h2>
            </div>
            <div className=' flex flex-col items-center'>
              <h2 className=" text-7xl font-semibold">150</h2>
              <h2 className="">Questions</h2>
            </div>
            <div className=' flex flex-col items-center'>
              <h2 className="text-7xl font-semibold">45</h2>
              <h2 className="">Test</h2>
            </div>
          </div>


        </section>

        {/* Section 3 */}
        <section className="px-[67px] flex flex-col pt-[50px] pb-[131px] text-center gap-[77px]">
          <h2 className="text-7xl px-[200px] font-semibold  text-[#3AA91B]">Optimisez Votre Préparation au TCF
            avec Notre Expertise</h2>
          <div className='flex justify-around'>

            <div className='flex flex-col gap-[11px] text-start w-[623px]'>
              <h2 className="text-3xl font-semibold  ">Avantages Personnalisés</h2>
              <p className="text-lg font-light  
               mb-[120px]">Chaque candidat est unique, et nous le savons.
                Profitez d'un accompagnement
                sur-mesure qui adapte
                nos méthodes pédagogiques à votre parcours, assurant
                un suivi détaillé de vos progrès dans chaque module.
                Votre réussite est
                notre priorité.
              </p>
              <h2 className="text-3xl font-semibold  ">Votre Salle de Classe Virtuelle</h2>
              <p className="text-lg font-light  
              ">Étudiez où que vous soyez, quand vous le souhaitez. Notre plateforme d'apprentissage
                en ligne vous connecte aux outils les plus avancés et aux méthodes interactives.
                Flexibilité et accessibilité, sans compromis sur la qualité, dès l'instant où vous rejoignez
                notre communauté
                d'apprenants.
              </p>
              <button className="text-white font-semibold text-lg w-[50%] py-[11px] rounded-md mt-[80px] " style={{
                background: 'linear-gradient(to right, rgba(3, 139, 20, 1), rgba(43, 255, 0, 1) 100%)',
              }}>Nos Formations</button>

            </div>
            <img src={homepagestudents} alt="Header" className=" h-[570px] object-cover transform " />

          </div>


        </section>

        {/* Section 4 */}
        <section className=" px-[30px]  flex flex-col  pb-[30px] text-center gap-[77px]">
          <div>
            <h2 className="text-7xl px-[350px] font-semibold  text-[#3AA91B] mb-1 ">Nos Forfait Pourformation
              Chez Nous</h2>
            <p className="text-lg font-semibold  text-[#6D6D6D] ">Faites vos choix, enregistrez-vous, et commencez aujourd'hui</p>
          </div>
          <div className="flex flex-wrap gap-4  justify-center">
            {cardData.map((card, index) => (
              <Card
                key={index}
                plan={card.plan}
                duration={card.duration}
                price={card.price}
                features={card.features}
                isGreen={index === 1}
              />
            ))}
          </div>


        </section>

        {/* Section 5 */}
        <section className=" w-full  relative overflow-hidden   ">

          <img src={tcf} alt="tcf" className="w-full h-[500px]  object-cover " />
          <div className=" absolute inset-0 right-0 flex  px-[98px]">
            <div className='flex flex-col justify-center gap-[31px] z-10'>
              <div className='w-[399px] flex flex-col items-start pt-[24px] gap-[7px] relative  '>
                {/* Text content - placed above the overlay */}
                <img src={logo} alt='' />
                <p className="text-lg  text-white font-normal">Nous vous aidons à certifier
                  votre niveau de français afin
                  de faciliter votre immigration
                  au Canada
                </p>
              </div>

            </div>
          </div>

        </section>

        {/* Section 6 */}
        <section className="px-[65px] flex flex-col py-[65px] text-center ">
          <div className='flex justify-around items-center'>
            <img src={assist} alt="Header" className=" w-[500px] h-[350px] object-cover transform " />

            <div className='flex flex-col gap-[11px] text-start '>
              <h2 className="text-3xl font-semibold  text-[#3AA91B]">À Votre Service, Où Que Vous Soyez!</h2>
              <p className="w-[455px] text-lg font-light  text-[#12372A]">Étudiez où que vous soyez, quand vous le souhaitez. Notre plateforme d'apprentissage
                en ligne vous connecte aux outils les plus avancés et aux méthodes interactives.
                Flexibilité et accessibilité, sans compromis sur la qualité, dès l'instant où vous rejoignez
                notre communauté
                d'apprenants.
              </p>
              <button className="w-[60%] text-white font-semibold text-lg px-[74px] py-[11px] rounded-md mt-[25px] " style={{
                background: 'linear-gradient(to right, rgba(3, 139, 20, 1), rgba(43, 255, 0, 1) 100%)',
              }}>Nos Formations</button>

            </div>
          </div>


        </section>


        {/* Section 7 */}
        <section className=" w-full  relative   overflow-hidden   ">
          <img src={background} alt="Header" className="w-full h-[590px]  object-cover transform " />
          <div className=" absolute inset-0  ">
          <h2 className="text-3xl  px-[260px] pt-10 text-start font-semibold  text-white mb-[12px]">Cantactez-nous</h2>

            {/* <div className='flex px-[260px] items-end pt-[36px] gap-[76px] pb-4'> */}
              
              <div className='w-[50%] flex flex-col pl-[100px] '>
                <InputField label="Nom" placeholder="Prenom & Nom" name={'firstName'} />
                <InputField label="Nom" placeholder="Exemple@mail.com" name={'firstName'} />
                <textarea
                  placeholder="Ecrire un message"
                  className="w-full p-4 border rounded-[15px] resize-none h-[250px] pr-10"
                  rows="4"
                >
                </textarea>
                <button className=" text-[#12372A] bg-[#00FF8B] font-semibold text-lg  py-[11px] rounded-md mt-[25px] ">Envoyez un message</button>
              </div>
              <div className=' absolute w-[40%] right-[100px] flex flex-col w-fit text-center bg-black  rounded-t-[20px] bottom-0 py-[64px] px-6' >
                <p className="text-lg   mb-[20px] bg-[#03FD30] font-light ">Telephone ou WhatsApp
                </p>
                <p className="text-lg  text-white font-light mb-[16px] ">Vous pouvez appeler ou nous envoyer des sms au:
                </p>
                <p className='text-white text-xl font-semibold mb-[16px] '>1-514-922-5004
                </p>
                <p className="text-lg text-white font-light  mb-[16px]">Ou nous ecrire sur WhatsApp
                </p>
                <p className='text-white text-xl font-semibold  mb-[16px] '>1-514-922-5004
                </p>
                <p className="text-xl  text-white font-bold mb-[16px] ">Email
                </p>
                <p className='text-[#03FD30] text-lg font-light '>Contact-Prepmontcfa.com
                </p>
              </div>
            {/* </div> */}
          </div>
        </section>



        {/* Section 8 */}
        <section className="hidden h-screen bg-gray-800 flex items-center justify-center">
          <h1 className="text-4xl font-bold text-white">Section 8</h1>
        </section>

        {/* Section 9 */}
        <section className=" px-[67px] flex flex-col pt-[95px] pb-[131px] text-center gap-[69px]">
          <div>
            <h2 className="text-7xl px-[308px] font-semibold  text-[#3AA91B] mb-[12px]">Quelque Avis de nos clients satisfaits
              du service</h2>
          </div>
          <div className="flex flex-wrap px-[30px] gap-[24px] xl:gap-[47px] justify-center">
            {clientData.map((clientcard, index) => (
              <ClientCard
                key={index}
                image={clientcard.image}
                username={clientcard.username}
                handle={clientcard.handle}
                testimonial={clientcard.testimonial}
              />
            ))}
          </div>
        </section>

      </div>
      <section className='bg-[#ECECEC] py-10'>
        <div className='flex flex-col items-center justify-center '>
          <p className='text-3xl font-bold mb-[5px] text-[#017149]'> Newsletters</p>
          <p className='px-[300px] text-center'>Lorem Lorem ipsum dolor sit amet, consectetuer adipiscing Lorem ipsum dolor sit amet,
            consectetuer adipiscing elit, sed diam nonummy nibh euismod </p>
          {/* <div className='flex gap-[8px] mt-[25px] '>
            <input
              type='text'
              placeholder='Exemple@mail.com'
              className=" flex-grow outline-none bg-white h-[45px] rounded-[10px] placeholder:text-tcfgray pl-[28px] relative "
            />
            <button className=" absolute bg-[#2CFF00] text-[#12372A] font-semibold text-lg px-[45px] py-[11px] rounded-[10px] ">Subscribe</button>
          </div> */}

          <div className=" flex items-center bg-white  rounded-[10px] mt-[25px] overflow-hidden">
            <input
              type="text"
              placeholder="Exemple@mail.com"
              className="px-14  outline-none focus:outline-none h-[45px] placeholder:text-tcfgray "
            />
            <button className="w-full bg-[#017149] text-white px-6  h-[45px] rounded-[10px]">
              Subscribe
            </button>
          </div>



        </div>
      </section>

      <Footer />

    </div>
  );
};

export default Home;
