// ResponseSection.js
import React, { useCallback, useState } from 'react';
import erroricon from '../images/exclamationicon.svg'
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentIndex, setExpressionWrittenResponse } from '../store/questionsSlice';
import apiClient from '../services/httpService';




const ResponseSection = ({ question }) => {
  const [text, setText] = useState('');
  const { questions, currentIndex, selectedTest } = useSelector((state) => state.questions);
  const dispatch = useDispatch();
  const maxWords = 120;
  


  // Function to count words
  const countWords = (text) => {
    // Trim spaces and split by spaces, filter out empty entries (e.g., multiple spaces)
    const words = text.trim().split(/\s+/);
    return words.filter(Boolean).length;
  };

  const handleChange = (event) => {
    dispatch(setExpressionWrittenResponse({ questionId: question.id, response: event.target.value }));
  };

  const handleNextQuestion = useCallback(() => {
    if (currentIndex < questions.expression_questions.length - 1) {
      dispatch(setCurrentIndex(currentIndex + 1));
    }
  }, [currentIndex, dispatch, questions.expression_questions.length]);

  const handlePreviousQuestion = useCallback(() => {
    if (currentIndex > 0) {
      dispatch(setCurrentIndex(currentIndex - 1));
    }
  }, [currentIndex, dispatch]);

  const handleFinishExam = async () => {
    const formDataQuestions = questions?.expression_questions;
    let valid = true;

    for (const question of formDataQuestions) {
      if (!question.response) {
        valid = false;
        alert('Please select an answer for all questions.');
        break; // Exit the loop immediately
      }
    }

    if (valid) {
      const response = await apiClient.post(`/mgmt/save-response-expression/${selectedTest}/`, {
        questions: questions.expression_questions,
      });
      if (response.status === 201) {
        console.log(`/results/${response?.data?.id}`);
      }
    }
  };
  console.log(question);
  
  // Get the word count
  const wordCount = countWords(text);
  return (
    <div className="bg-white py-4 px-4 rounded-[20px] shadow  flex flex-col gap-3 ">
      {!question && <div className="text-red-500 text-xl">Veuillez sélectionner un test</div>}
      <div className="text-white  w-full p-2 bg-tcfred rounded-md space-y-2">
        <div className='flex flex-row justify-between'>
          <p className='text-lg font-bold'> Consigne</p>

          <div className="w-[30px] h-[30px] flex items-center justify-center px-2 border-none rounded-full bg-white">
            <img src={erroricon} alt="" className="w-[24px] h-[24px]" />
          </div>
        </div>

        <p className=''> Rédaction d'un message pour decrire, raconte et/ou expliquer, adresser à un ou plusieurs destinataires, dont le status a été précisé dans la consigne.Minimum 60 mots - Maximum 120 mots</p>
      </div>

      {(question &&question?.task_number !== 3) &&
        <div className="flex flex-col items-start  bg-gray-100 p-5 rounded-[15px] gap-2">
          <div className='flex flex-row w-full justify-between'>
            <p className="text-lg font-normal">SUJET</p>
            <p className="text-lg font-bold  text-white rounded-md border-none bg-black px-3 ">{`Tache ${question?.task_number}`}</p>
          </div>
          <p className="text-[20px] leading-[30.86px] font-extrabold text-gray-800 ">
            {question?.description} <span className='text-tcfblue'>{question?.description_question}</span>
          </p>
          <p className="text-sm text-tcfred mt-1">Minimum 60 mots - Maximum 120 mots</p>
        </div>
      }
      {question?.task_number === 3 &&
        <>
          <div className="flex flex-col items-start  bg-gray-100 p-5 rounded-[15px] gap-2">
            <div className='flex flex-row w-full justify-between'>
              <p className="text-lg font-normal">Document 1</p>
            </div>
            <p className="text-[20px] leading-[30.86px] font-extrabold text-gray-800 ">
              {question?.document_one}
            </p>
          </div>
          <div className="flex flex-col items-start  bg-gray-100 p-5 rounded-[15px] gap-2">
            <div className='flex flex-row w-full justify-between'>
              <p className="text-lg font-normal">Document 2</p>
            </div>
            <p className="text-[20px] leading-[30.86px] font-extrabold text-gray-800 ">
              {question?.document_two}
            </p>
          </div>
          <div className="flex flex-col items-start  bg-gray-100 p-5 rounded-[15px] gap-2">
            <div className='flex flex-row w-full justify-between'>
              <p className="text-lg font-normal">SUJET</p>
              <p className="text-lg font-bold  text-white rounded-md border-none bg-black px-3 ">{`Tache ${question?.task_number}`}</p>
            </div>
            <p className="text-[20px] leading-[30.86px] font-extrabold text-gray-800 ">
              {question?.question}
            </p>
            {/* <p className="text-sm text-tcfred mt-1">Minimum 60 mots - Maximum 120 mots</p> */}
          </div>
        </>
      }


      <div className="relative w-full ">
        <textarea
          placeholder="Ecrire ici votre reponse ..."
          className="w-full p-4 border rounded-[15px] resize-none h-[250px] pr-10"
          rows="4"
          value={question?.response}
          onChange={handleChange}
        ></textarea>
        <p className="absolute bottom-4 right-4 text-xs ">{wordCount}/{maxWords} mots</p>
      </div>
      {currentIndex < questions.expression_questions.length - 1 && (
        <button
          className="bg-[#3aba03] text-white py-4 rounded-md items-center justify-center mb-4"
          onClick={handleNextQuestion}
          disabled={currentIndex >= questions.expression_questions.length - 1}
        >
          QUESTION SUIVANTE
        </button>
      )}
      {currentIndex > 0 && (
        <button onClick={handlePreviousQuestion} className="bg-[#3aba03] text-white  py-4 rounded-md  items-center justify-center mb-4">Question Précédente</button>
      )}
      {currentIndex === questions?.expression_questions.length - 1 && (
        <button onClick={handleFinishExam} className=" bg-tcfgreen text-white  py-4 rounded-md items-center justify-center mb-4">VALIDER VOTRE REPONSE</button>
      )}
    </div>
  );
}

export default ResponseSection;
