import React from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import homelogo from '../images/TCFlogo2.png';



const HomeNavigation = () => {
    const navigate = useNavigate()
    return (
        <nav className=" fixed top-0 left-0 right-0  text-white text-lg bg-[#02A015] h-[85px] flex items-center justify-between px-[33px]  xl:px-[45px] z-50 ">
            {/* Logo in Navbar */}
            <div className="flex items-center gap-8">
                <NavLink to="/" className="" >
                    <img src={homelogo} alt="Home Logo" className="h-[28.61px] w-[146px] lg:h-[32.33px] lg:w-[165px] xl:h-[52px] xl:w-[115px] " />
                </NavLink>


                {/* Navbar Links (hidden on mobile) */}
                <div className="hidden md:flex md:space-x-[22px] lg:space-x-[30px] items-center text-sm font-[600] ">
                    <NavLink to="/">
                        Compréhension Écrite
                    </NavLink>
                    <NavLink to="/">
                        Compréhension Orale
                    </NavLink>
                    <NavLink to="/">
                        Expression Écrite
                    </NavLink>
                    <NavLink to="/">
                        Expression Orale
                    </NavLink>
                    {/* More Dropdown large screens */}
                </div>
            </div>
            <div>

                <button onClick={() => { navigate("/login") }} className=' flex  text-[#02A015] px-[53px] items-center justify-center text-base lg:text-sm bg-white h-[30px] lg:h-[40px] rounded-md flex-shrink-0 leading-[18.9px]  font-medium'>Log In

                </button>




            </div>



        </nav>
    );
};

export default HomeNavigation;
