import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentIndex: 0,
  tests: [],
  selectedTest: null,
  questions: {
    normal_questions: [],
    expression_questions: [],
    duration: 0,
  },
  response: [],
};

const questionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setSelectedTest: (state, action) => {
      state.selectedTest = action.payload;
    },
    setQuestions: (state, action) => {
      state.questions = action.payload;
    },
    setResponse: (state, action) => {
      state.response = [...state.response, action.payload];
    },
    setCurrentIndex: (state, action) => {
      state.currentIndex = action.payload;
    },
    setComprehensionWrittenResponse: (state, action) => {
      const { questionId, response } = action.payload;
      const question = state.questions.normal_questions.find(
        (q) => q.id === questionId
      );
      if (question) {
        question.response = response;
      }
    },
    setExpressionWrittenResponse: (state, action) => {
      const { questionId, response } = action.payload;
      const question = state.questions.expression_questions.find(
        (q) => q.id === questionId
      );
      if (question) {
        question.response = response;
      }
    },
    setComprehensionWrittenReviseStatus: (state, action) => {
      const { questionId } = action.payload;
      const question = state.questions.normal_questions.find(
        (q) => q.id === questionId
      );
      if (question) {
        if (question.revise_question === true) {
          question.revise_question = false;
        } else {
          question.revise_question = true;
        }
      }
    },
  },
});
export const {
  setComprehensionWrittenResponse,
  setSelectedCategory,
  setQuestions,
  setResponse,
  setCurrentIndex,
  setComprehensionWrittenReviseStatus,
  setSelectedTest,
  setExpressionWrittenResponse,
} = questionsSlice.actions;
export default questionsSlice.reducer;
