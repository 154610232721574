// components/QuestionCard.js
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setComprehensionWrittenResponse, setComprehensionWrittenReviseStatus, setCurrentIndex } from '../store/questionsSlice';
import apiClient from '../services/httpService';

function QuestionCard({ question }) {
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const { questions, currentIndex, selectedTest } = useSelector((state) => state.questions);

  const dispatch = useDispatch();
  const handleSelect = (response) => {
    setSelectedAnswer(response);
    dispatch(setComprehensionWrittenResponse({ questionId: question.id, response }));
  };

  const handleReviseQuestion = () => {
    dispatch(setComprehensionWrittenReviseStatus({ questionId: question.id }));
  };

  const handleNextQuestion = useCallback(() => {
    if (currentIndex < questions.normal_questions.length - 1) {
      dispatch(setCurrentIndex(currentIndex + 1));
    }
  }, [currentIndex, dispatch, questions.normal_questions.length]);

  const handlePreviousQuestion = useCallback(() => {
    if (currentIndex > 0) {
      dispatch(setCurrentIndex(currentIndex - 1));
    }
  }, [currentIndex, dispatch]);

  const handleFinishExam = async () => {
    const formDataQuestions = questions?.normal_questions;
    let valid = true;

    for (const question of formDataQuestions) {
      if (!question.response) {
        valid = false;
        alert('Please select an answer for all questions.');
        break; // Exit the loop immediately
      }
    }

    if (valid) {
      const response = await apiClient.post(`/mgmt/save-response/${selectedTest}/`, {
        questions: questions.normal_questions,
      });
      if (response.status === 201) {
        console.log(`/results/${response?.data?.id}`);
      }
    }
  };


  return (
    <div className="grid grid-cols-2 gap-5 mt-4">
      {question?.options?.map((option, i) => (
        <button
          key={i}
          onClick={() => handleSelect(option.value)}
          className={`text-center flex items-center justify-start px-3 py-5 border rounded-md ${selectedAnswer === question.id ? "bg-tcfbackground" : ""
            }`}
        >
          <input
            type="checkbox"
            name={`answer-${question.text}`}
            value={option.value}
            checked={question?.response === option.value}
            className="w-4 h-4 mr-4"
            onChange={() => handleSelect(option.value)}
          />
          {option.text}
        </button>
      ))}
      <button
        className="bg-[#3aba03] text-white py-4 rounded-md items-center justify-center mb-4"
        onClick={handleReviseQuestion}
      >Revise</button>
      {currentIndex < questions.normal_questions.length - 1 && (
        <button
          className="bg-[#3aba03] text-white py-4 rounded-md items-center justify-center mb-4"
          onClick={handleNextQuestion}
          disabled={currentIndex >= questions.normal_questions.length - 1}
        >
          QUESTION SUIVANTE
        </button>
      )}
      {currentIndex > 0 && (
        <button onClick={handlePreviousQuestion} className="bg-[#3aba03] text-white  py-4 rounded-md  items-center justify-center mb-4">Question Précédente</button>
      )}
      {currentIndex === questions?.normal_questions.length - 1 && (
        <button onClick={handleFinishExam} className="bg-[#3aba03] text-white py-4 rounded-md items-center justify-center mb-4">Terminer</button>
      )}
    </div>
  );
}

export default QuestionCard;